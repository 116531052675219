<template>
  <div>
    <b-table
      :items="list"
      :fields="fields"
      hover
      class="app-table"
    >
      <template #[`head(service.title)`]>
        <div class="app-table__filter-input">
          <b-form-input
            v-model="service"
            type="text"
            class="app-table__filter-input"
            placeholder="Service"
          />
          <i
            v-if="service"
            class="bx bxs-x-circle app-table__filter-clear"
            @mousedown.prevent="service = ''"
          />
        </div>
      </template>
      <template #[`head(email.email)`]>
        <div class="app-table__filter-input">
          <b-form-input
            v-model="email"
            type="text"
            class="app-table__filter-input"
            placeholder="Email"
          />
          <i
            v-if="email"
            class="bx bxs-x-circle app-table__filter-clear"
            @mousedown.prevent="email = ''"
          />
        </div>
      </template>
      <template #head(roles)="{ label }">
        <dropdown-select
          v-model="role"
          right
          :options="roles"
          valueKey="id"
          label="title"
          :allOptionsText="label"
        />
      </template>
      <template #cell(roles)="{ value, item }">
        <div
          @click="$emit('onEdit', item)"
          class="app-table__row-link cursor-pointer"
        />
        <div class="d-flex justify-content-end" style="gap: 10px 10px;">
          <div
            v-for="role in value"
            :key="role.id"
            :class="[
              'role-badge',
              { 'role-badge_red': role.title.toLowerCase() === 'owner' }
            ]"
          >
            {{ role.title }}
          </div>
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex align-items-center justify-content-end">
          <i
            style="z-index: 2;"
            class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer position-relative"
            @click="$emit('onEdit', item)"
          />
          <i
            style="z-index: 2;"
            class="bx bx-trash text-danger font-size-20 cursor-pointer position-relative"
            @click="$emit('onDelete', item)"
          />
        </div>
      </template>
    </b-table>
    <empty-page v-if="!list.length" />
  </div>
</template>

<script>
import flatten from 'lodash/flatten';
import EmptyPage from '@/components/empty-page.vue';
import DropdownSelect from '@/components/dropdown-select.vue';

export default {
  components: {
    EmptyPage,
    DropdownSelect
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      role: { id: null, title: 'Roles' },
      email: '',
      service: '',
      fields: [
        { key: 'service.title', label: 'Services', thStyle: { minWidth: '300px', width: '300px', maxWidth: '300px' } },
        { key: 'email.email', label: 'Email' },
        { key: 'roles', label: 'Roles', thClass: 'text-right' },
        { key: 'actions', label: '', thStyle: { minWidth: '100px', width: '100px', maxWidth: '100px' } },
      ]
    }
  },
  computed: {
    list() {
      let list = [...this.rows];

      if (this.service) {
        list = list.filter(
          ({ service }) => service.title.toLowerCase().includes(this.service.toLowerCase())
        );
      }
      if (this.email) {
        list = list.filter(
          ({ email }) => email.email.toLowerCase().includes(this.email.toLowerCase())
        );
      }
      if (typeof this.role.id === 'number') {
        list = list.filter(
          ({ roles }) => roles.some(({ title }) => title.toLowerCase() === this.role.title.toLowerCase())
        );
      }
      return list;
    },
    roles() {
      const roles = flatten(this.rows.map(({ roles }) => roles.map(({ title }) => title.toLowerCase())));
      return [...new Set(roles)].map((title, index) => ({ id: index, title }));
    }
  }
}
</script>

<style lang="scss" scoped>
.role-badge {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  padding: 0 4px;
  color: #343A40;
  border-radius: 4px;
  display: inline-block;
  text-transform: capitalize;
  background: rgba(52, 58, 64, 0.18);

  &_red {
    color: #fff;
    background: #DC3545;
  }
}
.empty {
  :deep(img) {
    max-width: 300px;
  }
}
</style>
