<template>
  <layout :loading="loading">
    <div
      v-if="employee"
      class="employee"
    >
      <div class="employee__header">
        <b-button
          variant="secondary"
          class="icon-button back-button"
          @click="goToEmployeesPage"
        >
          <i class="bx bx-arrow-back font-size-16" />
        </b-button>
        <h1 class="title-1">{{ employee.name }}</h1>
      </div>
      <b-card>
        <div class="d-flex align-items-start">
          <b-avatar
            variant="info"
            size="80px"
            :text="getNameInitials(employee.name)"
            :src="employee.photo"
          />
          <div class="employee__info">
            <div
              v-for="position in employee.positions"
              :key="position.id"
              class="employee__position"
            >
              {{ position.title }}
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="title-3">Emails ({{ employee.emails.length }})</h3>
          <b-button
            variant="success"
            @click="handleOpenEmailModal()"
          >
            Add Email
          </b-button>
        </div>
        <emails-table
          :rows="employee.emails"
          class="employee__table"
          @onEdit="handleOpenEmailModal"
          @onDelete="handleDeleteEmail"
        />
      </b-card>
      <b-card>
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="title-3">Services ({{ employee.services_employees.length }})</h3>
          <b-button
            variant="success"
            @click="handleOpenServiceModal()"
          >
            Add Service
          </b-button>
        </div>
        <services-table
          :rows="employee.services_employees"
          class="employee__table"
          @onEdit="handleOpenServiceModal"
          @onDelete="handleDeleteServiceEmployee"
        />
      </b-card>
    </div>
    <email-modal-form
      :mode="formMode"
      :modalId="modalId"
      :initialData="currentEmail"
      hide-employee
      @onSubmit="handleEmailFormSubmit"
    />
    <ServiceModal
      v-model="isServiceModalOpen"
      :mode="formMode"
      :initialData="currentServiceEmployee"
      :emails="employee?.emails ?? []"
      @onSubmit="handleServiceModalSubmit"
    />
  </layout>
</template>

<script>
import Layout from '@/router/layouts/main.vue';
import { FORM_MODE } from "@/utils/constants";
import { getNameInitials } from '@/utils/user';
import EmailModalForm from '@/components/emailModalForm.vue';
import EmailsTable from '@/components/employee/emails-table.vue';
import ServicesTable from '@/components/employee/services-table.vue';
import ServiceModal from '@/components/employee/service-modal.vue';

export default {
  components: {
    Layout,
    EmailsTable,
    ServiceModal,
    ServicesTable,
    EmailModalForm
  },
  data() {
    return {
      loading: false,
      employeeUid: null,
      currentEmail: null,
      currentServiceEmployee: null,
      modalId: 'email-modal',
      formMode: FORM_MODE.CREATE,
      isServiceModalOpen: false
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.loading = true;
      this.employeeUid = id;
      await this.fetchEmployee();
      this.$store.dispatch('services/getServices');
      this.loading = false;
    }
  },
  methods: {
    getNameInitials,
    fetchEmployee() {
      this.$store.dispatch('employees/fetchEmployee', this.employeeUid);
    },
    goToEmployeesPage() {
      this.$router.push({ name: 'employees' });
    },
    handleOpenEmailModal(item = null) {
      this.currentEmail = item;
      this.formMode = item ? FORM_MODE.EDIT : FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    handleOpenServiceModal(item = null) {
      this.currentServiceEmployee = item;
      this.formMode = item ? FORM_MODE.EDIT : FORM_MODE.CREATE;
      this.isServiceModalOpen = true;
    },
    async handleEmailFormSubmit({ email, description }) {
      const data = { email, description, employee_uid: this.employeeUid };
      this.loading = true;
      this.$bvModal.hide(this.modalId);
      if (this.formMode === FORM_MODE.CREATE) {
        await this.$store.dispatch('emails/createEmail', data);
      }
      if (this.formMode === FORM_MODE.EDIT) {
        await this.$store.dispatch('emails/updateEmail', {
          id: this.currentEmail.id,
          data 
        });
      }
      await this.fetchEmployee();
      this.loading = false;
    },
    handleDeleteEmail(item) {
      this.$bvModal.msgBoxConfirm('Delete this email?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            this.loading = true;
            await this.$store.dispatch('emails/deleteEmail', item.id);
            await this.fetchEmployee();
            this.loading = false;
          }
        })
    },
    async handleServiceModalSubmit({ data, isCreateRole, role }) {
      try {
        const roles = [...data.roles];
        this.isServiceModalOpen = false;
        this.loading = true;

        if (isCreateRole) {
          const response = await this.$store.dispatch('roles/createRole', {
            title: role.title,
            description: role.description,
            service_id: data.service.id,
          });
          roles.push(response);
        }

        const payload = {
          email_id: data.email?.id,
          employee_uid: this.employeeUid,
          service_id: data.service.id,
          roles: roles.map(({ id }) => id),
          login: data.login,
        }
        if (this.formMode === FORM_MODE.CREATE) {
          await this.$store.dispatch('services/addServiceUser', payload);
        } else {
          await this.$store.dispatch('services/updateServiceUser', {
            id: this.currentServiceEmployee.id,
            data: payload
          });
        }

        await this.fetchEmployee();
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }      
    },
    handleDeleteServiceEmployee(item) {
      this.$bvModal.msgBoxConfirm('Delete this service?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async (value) => {
            if (value) {
              this.loading = true;
              await this.$store.dispatch('services/deleteServiceUser', item.id);
              await this.fetchEmployee();
              this.loading = false;
            }
          })
    }
  },
  computed: {
    employee() {
      return this.$store.state.employees.employee;
    },
  }
}
</script>

<style lang="scss" scoped>
.employee {
  &__header {
    display: flex;
    margin: 20.5px 0px;
    align-items: center;
  }
  &__position {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    color: #74788D;
  }
  &__info {
    margin-left: 20px;
  }
  &__table {
    width: calc(100% + 40px);
    margin: 20px -20px 0px -20px;
  }
}
</style>
