<template>
  <b-modal
    v-model="isOpen"
    size="md"
    centered
    :title="isCreateMode ? 'Add Service' : 'Edit Service'"
    @hide="handleHideModal"
  >
    <b-form-group
      v-if="isCreateMode"
      label="Service"
      label-for="service"
      class="required"
    >
      <multiselect
        id="service"
        v-model="form.service"
        :options="services"
        searchable
        track-by="id"
        label="title"
      />
    </b-form-group>
    <b-form-group
      label="Owner email"
      label-for="owner_email"
      class="required"
    >
      <multiselect
        id="owner_email"
        v-model="form.email"
        :options="emails"
        searchable
        track-by="id"
        label="email"
      />
    </b-form-group>
    <b-form-group>
      <div class="d-flex">
        <b-form-radio v-model="isCreateRole" :value="false" class="col-4">Choose roles</b-form-radio>
        <b-form-radio v-model="isCreateRole" :value="true" class="col-4">Add role</b-form-radio>
      </div>
    </b-form-group>
    <template v-if="isCreateRole">
      <b-form-group
        label="Role title"
        label-for="role-title"
        class="required"
      >
        <b-form-input
          id="role-title"
          v-model="role.title"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="Role Description"
        label-for="role-description"
        class="required"
      >
        <b-form-textarea
          id="role-description"
          v-model="role.description"
        />
      </b-form-group>
    </template>
    <b-form-group
      v-else
      label="Roles"
      label-for="role"
      class="required"
    >
      <multiselect
        v-model="form.roles"
        :options="roles"
        multiple
        track-by="id"
        label="title"
      />
    </b-form-group>
    <b-form-group
      label="Login"
      label-for="login"
  >
    <b-form-input
      id="login"
      v-model="form.login"
      type="text"
    />
  </b-form-group>
    <template #modal-footer>
      <b-button
        variant="primary"
        class="templates-modal__button"
        :disabled="isSaveButtonDisabled"
        @click="handleSubmit"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { FORM_MODE } from "@/utils/constants";

export default {
  props: {
    value: Boolean,
    emails: Array,
    mode: String,
    initialData: Object
  },
  data() {
    return {
      form: {
        roles: [],
        login: '',
        email: null,
        service: null,
      },
      role: {
        title: '',
        description: ''
      },
      isCreateRole: false,
      loading: false,
      selectedTemplate: null
    }
  },
  methods: {
    handleHideModal() {
      this.form.login = '';
      this.form.roles = [];
      this.form.email = null;
      this.form.service = null;
      this.role.title = '';
      this.role.description = '';
      this.isCreateRole = false;
    },
    async handleSubmit() {
      this.$emit('onSubmit', {
        data: { ...this.form },
        isCreateRole: this.isCreateRole,
        role: this.role
      });
    },
  },
  computed: {
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    services() {
      return this.$store.state.services.list;
    },
    roles() {
      return this.form?.service?.roles ?? [];
    },
    isCreateMode() {
      return this.mode === FORM_MODE.CREATE;
    },
    isSaveButtonDisabled() {
      const role = this.isCreateRole ? (this.role.title && this.role.description) : this.form.roles.length;
      return !(this.isCreateMode ? (role && this.form.service && this.form.email) : (role && this.form.email));
    }
  },
  watch: {
    isOpen(value) {
      if (value && this.initialData && !this.isCreateMode) {
        this.form.email = this.initialData.email ?? null;
        this.form.service = this.services.find(({ id }) => id === this.initialData.service?.id) ?? null;
        this.form.roles = this.initialData.roles ?? [];
        this.form.login = this.initialData.login ?? '';
      }
    }
  }
}
</script>
