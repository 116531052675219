<template>
  <div>
    <b-table
      :items="list"
      :fields="fields"
      hover
      class="app-table"
    >
      <template #head(email)>
        <div class="app-table__filter-input">
          <b-form-input
            v-model="search"
            type="text"
            placeholder="Email"
          />
          <i
            v-if="search"
            class="bx bxs-x-circle app-table__filter-clear"
            @mousedown.prevent="search = ''"
          />
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex align-items-center justify-content-end">
          <i
            class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer"
            @click="$emit('onEdit', item)"
          />
          <i
            class="bx bx-trash text-danger font-size-20 cursor-pointer"
            @click="$emit('onDelete', item)"
          />
        </div>
      </template>
    </b-table>
    <empty-page v-if="!list.length" />
  </div>
</template>

<script>
import EmptyPage from '@/components/empty-page.vue';

export default {
  components: {
    EmptyPage
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: '',
      fields: [
        { key: 'email', label: 'Email' },
        { key: 'actions', label: '' },
      ]
    }
  },
  computed: {
    list() {
      if (this.search) {
        return this.rows.filter(
          ({ email }) => email.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.rows;
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  :deep(img) {
    max-width: 300px;
  }
}
</style>
